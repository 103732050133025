import React from 'react'
import { Radio, RadioChangeEvent } from 'antd'
import { ScheduleItemType } from '@/components/ScheduleSelector/ScheduleSelector'

export type Props = {
	disabled: boolean
	value: any
	options: any[]
	onChange: (value: ScheduleItemType) => void
}

const ScheduleSelectComponent: React.FC<Props> = ({ value, options, onChange, disabled = false }) => {
	const handleChange = (event: RadioChangeEvent) => {
		onChange(event.target.value)
	}

	return (
		<Radio.Group
			disabled={disabled}
			value={value}
			className="grf-radio-group md:w-[360px] w-[350px] "
			name="schedule"
			buttonStyle="solid"
			onChange={handleChange}
		>
			{options.map((item: { label: string; value: ScheduleItemType }) => {
				const selectedClass = item?.value?.start_time === value?.start_time ? 'schedule-selected' : ''

				return (
					<Radio.Button className={`${selectedClass}`} key={item.label} value={item.value}>
						{item.label}
					</Radio.Button>
				)
			})}
		</Radio.Group>
	)
}

export default ScheduleSelectComponent
