import PropTypes from 'prop-types'
import Image from 'next/image'

const Loader = ({ size = 38, label = 'Cargando...' }) => (
	<div className="loader">
		<div className="loader-inner text-center">
			<Image src="/assets/imgs/loader.svg" alt="loading" title="Cargando" width={size} height={size} />
			<div className="text-xs text-gray-500">{label}</div>
		</div>
	</div>
)

Loader.propTypes = {
	size: PropTypes.number,
	label: PropTypes.string,
}

export default Loader
