import React from 'react'
import { Button } from 'antd'

export type Props = {
	onRegisterClick: () => void
	onOrderClick: () => void
}

const PopupBlock: React.FC<Props> = ({ onRegisterClick, onOrderClick }) => {
	return (
		<div className="customer-popup-block grid place-content-center gap-4 pt-8 pb-16 ">
			<h2 className="text-2xl text-center py-4">
				<span className="font-medium">¿Qué deseas hacer?</span>
			</h2>
			<div className="flex w-full gap-4 flex-col md:flex-row">
			<Button onClick={onRegisterClick} className="btn-black">
					<span className="w-[220px] p-1 font-bold text-[#FFFF] font-medium ">
						<div>No soy cliente,</div>
						<div>deseo registrarme</div>
					</span>
				</Button>
				<Button onClick={onOrderClick} className="btn-black">
					<span className="w-[220px]  p-1  font-bold text-[#FFFF] font-medium">
						Soy cliente <span className="font-black">Väissen,</span>  <div> quiero hacer un pedido</div>
					</span>
				</Button>
			</div>
		</div>
	)
}

export default PopupBlock
