import React, { useState, useEffect, useCallback } from 'react'
import { message } from 'antd'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import * as yup from 'yup'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { motion } from 'framer-motion'
import SimpleCarouselSection from '@/components/SimpleCarouselSection'
import AddressCover from '@/components/AddressCover'
import SectionOfferServices from '@/components/SectionOfferServices'
import SectionCustumer from '@/components/SectionCustumer'
import AddressView from '@/components/AddressView'
import DaysSelector from '@/components/DaysSelector'
import ScheduleSelector from '@/components/ScheduleSelector'
import Button from '@/ui/Button'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { selectRegister, setScheduleData } from '@/features/register/registerSlice'
import { paths, SERVICE_TYPES } from '@/config/app'
import { getDateOfFirstVisit } from '@/services/customers'
import Separator from '@/components/Separator'
import { ScheduleItemType } from '@/components/ScheduleSelector/ScheduleSelector'
import { verifyViability } from '@/services/app.service'
import { fetchProducts } from '@/services/products.service'
import SingleProductList from '@/components/SingleProductList'
import type { Product } from '@/types/products'
import { isMobile } from 'react-device-detect'
import FrecuencySelector from '@/components/FrecuencySelector'

type ViabilityType = {
	[key: string]: boolean
}

const checkViability = (_data: ViabilityType, _selected: string[]): [boolean, string | null] => {
	if (Object.entries(_data).length) {
		for (let [day, value] of Object.entries(_data)) {
			if (_selected.includes(day) && value === false) {
				return [false, day]
			}
		}

		return [true, null]
	}

	return [false, null]
}

const schema = yup
	.object({
		// service: yup.string().required('El campo preferencia es requerido').nullable(),
		schedule: yup.object().required('El campo horario es requerido').nullable(),
		days: yup
			.array()
			.required('El campo días es requerido')
			.min(1, 'Debe seleccionar mínimo 1 día')
			.max(1, 'Debe seleccionar máximo 1 día'),

		apartment: yup.string().required('El campo tipo es requerido').nullable(),
		street: yup.string().required('El campo calle es requerido'),
		colony: yup.string().required('El campo colonia es requerido'),
		state: yup.string().required('El campo estado es requerido'),
		ext_number: yup
			.string()
			//.positive('Debe ser un valor positivo')
			//.integer('Debe ser un valor entero')
			.required('El campo numero exterior es requerido')
			.nullable(),
		int_number: yup.string().nullable(),
		zip_code: yup
			.string()
			.required('El campo código postal es requerido'),
			// .min(5, 'Debe ser de 5 caracteres')
			// .max(5, 'Debe ser de 5 caracteres'),
		tower_depot_number: yup.string().when('apartment', (apartment, schema) => {
			return apartment && parseInt(apartment) > 7
			  ? schema.required('El campo nombre del edificio es requerido').nullable()
			  : schema;
		  }),
	})
	.required()

export type ScheduleDataType = {
	service: string | null
	apartment: string | null
	schedule: {
		start_time: string
		final_time: string
	} | null
	days: string[]
	visit_frecuency:string[]
}

export type Props = {}

const HomeView: React.FC<Props> = () => {
	const [status, setStatus] = useState<string>('idle')
	const [status2, setStatus2] = useState<string>('idle')
	const [products, setProducts] = useState<Product[]>([])
	const router = useRouter()
	const dispatch = useAppDispatch()
	const register = useAppSelector(selectRegister)

	const {
		address,
		availability,
		location,
		schedule: { apartment },
	} = register.payload

	const methods = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: {
			apartment: null,
			schedule: null,
			service: 'GENDER',//null
			days: [],
			...address,
		},
	})

	const {
		handleSubmit,
		setError,
		resetField,
		getValues,
		formState: { isValid, errors },
	} = methods

	// Handlers
	const handleScheduleChange = async (schedule: ScheduleItemType) => {
		const selectedDays: string[] = getValues('days')
		if (!apartment) {
			message.warn('Selecciona un tipo de vivienda!')
			return
		}

		if (selectedDays.length === 0) {
			message.warn('Selecciona un día de entrega!')
			return
		}

		const close = message.loading('Buscando disponibilidad, este proceso puede tomar unos segundos...', 0)
		try {
			// Set loading
			setStatus('loading')

			// API Call
			const response: ViabilityType = await verifyViability({
				name: '',
				neighborhood: address.colony,
				zip_code: address.zip_code,
				lat: location.lat,
				lng: location.lng,
				window_start: schedule.start_time,
				window_end: schedule.final_time,
				apartment: apartment,
				day: selectedDays[0],
			})

			if (false === response?.result) {
				setError('schedule', { type: 'focus' }, { shouldFocus: true })
				message.warn('No hay disponibilidad en el horario seleccionado', 5)
				//- resetField('schedule')
			} else {
				const days = selectedDays
				const firstDayVisit = getDateOfFirstVisit(days, schedule.final_time)
			
				message.success(
					'Tenemos disponibilidad para entrega el: ' + dayjs(firstDayVisit).format('dddd DD MMMM YYYY'),
					5
				)
			}
		} catch (error: any) {
			if (typeof error?.message === 'string') {
				message.error(error.message)
			} else {
				//- console.log('error::', error)
				message.warn('No hay disponibilidad en el horario seleccionado', 5)
				setError('schedule', { type: 'focus' }, { shouldFocus: true })
			}
		} finally {
			setStatus('idle')
			close()
		}
	}



	const handleServiceChange = async (service: string) => {
		// When the user changes the service in select
		const close = message.loading('Buscando lista de precios...', 0)
		setStatus2('loading')
		resetField('schedule')
		try {
			let filter = {}

			if (service === SERVICE_TYPES.RANGER) {
				filter = { is_range: true }
			} else if (service === SERVICE_TYPES.GENDER) {
				filter = { is_generic: true }
			} else {
				filter = { is_order: true }
			}

			// API Call
			const products = await fetchProducts({
				neighborhood: address.colony,
				code_zip: address.zip_code,
				...filter,
			})
			const listProductsWithOut = products.map((products) => products).filter((products) => products.price > 0)
			setProducts(listProductsWithOut)
		} catch (error: any) {
			message.error(error.message)
		} finally {
			setStatus2('idle')
			close()
		}
	}

	// Submit your data into Redux store
	const onSubmit: SubmitHandler<ScheduleDataType> = (data: ScheduleDataType) => {
		const { schedule, service, apartment, days ,visit_frecuency} = data
		if (schedule && days.length) {
			const firstDayVisit = getDateOfFirstVisit(days, schedule.final_time)
			dispatch(
				setScheduleData({
					first_day_visit: firstDayVisit,
					hours: schedule,
					service:'GENDER',//SERVICE
					apartment,
					days,
					visit_frecuency,
				})
			)
			router.push(paths.register.customer.path)
		}
	}

	if (errors) {
		// console.log('Form::errors', errors)
	}



	return (
		<div className="register-view">
			<FormProvider {...methods}>
				<SimpleCarouselSection />
				{/* <AddressCover /> */}
				<form id="register" className="register-form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<AddressView />
					<section id="schedule" className="schedule-section bg-[#FFFFFF] ">
						<div className="container w-full mx-auto px-6">
							<div className="grid grid-cols-1 md:grid-cols-2  justify-evenly">
								<div className="flex flex-col justify-evenly">
									<div className="mx-auto  w-full">
										<DaysSelector availability={availability} />
									</div>
								
									<div className=" mx-auto  w-full">
										<ScheduleSelector
											availability={availability}
											status={status}
											onScheduleChange={handleScheduleChange}
											onServiceChange={handleServiceChange}
										/>
									</div>

									<div className="mx-auto  w-full">
										<FrecuencySelector availability={availability} />
									</div>
								</div>
								{isMobile ? null : (
									<div className=" flex-col flex justify-center     justify-items-center ">
										<div
											className='bg-[url("/assets/imgs/union_path.png")] bg-contain  bg-no-repeat ml-auto'
											style={{ width: 375, height: 500 }}
										></div>
										<div className=" ml-auto      justify-end">
											<motion.div
												animate={{ opacity: availability.length > 0 ? 1 : 0.5 }}
												transition={{ duration: 0.3 }}
											>
												<footer className=" md:max-w-[380px] w-full justify-end ">
													<Button
														// icon={
														// 	<img
														// 		src="/assets/imgs/btn-icon.svg"
														// 		alt="success"
														// 		className="inline-block"
														// 	/>
														// }
														className="button-bg-blue grf-btn w-full drop-shadow-lg "
														size="large"
														htmlType="submit"
														disabled={status === 'loading' || !isValid}
													>
														<span className=" ">Confirmar y continuar</span>
													</Button>
												</footer>
											</motion.div>
										</div>
									</div>
								)}
							</div>
						</div>
					</section>
					<div className=" flex flex-col-reverse md:grid  md:grid-cols-1 justify-evenly">
						{isMobile ? (
							<div className=" mx-auto  mb-5 ">
								<motion.div
									animate={{ opacity: availability.length > 0 ? 1 : 0.5 }}
									transition={{ duration: 0.3 }}
								>
									<footer className="mx-auto md:max-w-[380px] w-full px-6">
										<Button
											// icon={
											// 	<img
											// 		src="/assets/imgs/btn-icon.svg"
											// 		alt="success"
											// 		className="inline-block"
											// 	/>
											// }
											className="button-bg-blue grf-btn  border-none w-full drop-shadow-lg "
											size="large"
											htmlType="submit"
											disabled={status === 'loading' || !isValid }
										>
											<span className="pl-4 ">Confirmar y continuar</span>
										</Button>
									</footer>
								</motion.div>
							</div>
						) : null}
						<SingleProductList products={products} status={status2} />
					</div>
				</form>
				{/* <SectionOfferServices />
				<SectionCustumer /> */}
			</FormProvider>
		</div>
	)
}

export default HomeView