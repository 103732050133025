import React, { useState, useEffect } from 'react'
import { default as ReactSimplyCarousel } from 'react-simply-carousel'
import { getDeviceType } from './getDeviceType'
import Button from '@/ui/Button'
import Carousel from 'react-multi-carousel'
import TitleElement from '@/ui/TitleElement'
import TextElement from '@/ui/TitleElement'
import Link from '../NoScrollLink'
import { Divider } from 'antd'

const responsive = {
	tablet: {
		breakpoint: { max: 4024, min: 564 },
		items: 1,
	},
	mobile: {
		breakpoint: { max: 564, min: 0 },
		items: 1,
	},
}

const SimpleCarouselSection = () => {
	return (
		<section id="inicial_registro" className="bg-initial-section bg-gradient-to-b from-transparent to-[#D4ECF8] ">
			<div className=" initial-section md:min-h-[600px]  bg-[url('/assets/imgs/Landing_Vaissen_desktop.png')]   bg-cover  mx-auto  md:min-w-[1422px] "></div>
			<div className=" container w-full mx-auto px-6">
				<main className="main py-4 grid grid-cols-1 md:grid-cols-2">
					<div className="min-w-[320px] min-h-[315px]  md:min-h-[600px]  md:max-w-[620px] w-full  flex flex-col justify-evenly   my-auto ">
						<TitleElement className=" pt-2  text-left  mx-auto w-full">
							¡Cámbiate a <span className="font-[600]">Väissen</span>! <br />
							<br />
							El agua de auténtico manantial con el servicio premium que mereces
						</TitleElement>

						{/* <p className="md:text-left  hidden lg:block px-1 mb-12 mt-[2rem]  font-text text-1xl md:text-2xl text-[#888888]">
								Prueba la <span className='font-[400]'> Entrega Preferente</span> y recibe tu garrafón en el horario que necesites.
							</p> */}

						{/* <div
							className='bg-[url("/assets/imgs/Entrega-preferente.svg")] bg-no-repeat my-[3rem] bg-cover bg-[top_2rem]  md:ml-0 mx-auto'
							style={{ width: 265, height: 126 }}
						></div> */}

						{/* <span className="md:text-left  hidden lg:block  text-[#999999] text-xs px-1 mb-12 mt-[2rem]">
							*Válido al 30 de Septiembre del 2022 o Agotar Existencias.
						</span> */}

						<div className="mx-auto w-full text-center md:text-left  ">
							<Link href="#registro">
								<Button
									className="button-bg-blue mb-6  max-w-[320px] min-w-[320px]"
									size="large"
									htmlType="submit"
								>
									<span className=" text-2xl ">Iniciar registro</span>
								</Button>
							</Link>
						</div>
					</div>
					<div className="bg-movil md:hidden   overflow-hidden   md:max-w-[620px]   min-w-[320px] min-h-[315px] bg-[url('/assets/imgs/Landing_Vaissen.png')]   bg-cover  mx-auto w-full "></div>
				</main>
			</div>
		</section>
	)
}

export default SimpleCarouselSection
