import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ErrorItem from '@/components/AddressView/ErrorItem'
import { useAppSelector } from '@/app/hooks'
import { selectPreferences } from '@/features/preferences/preferencesSlice'
import ScheduleSelectComponent from '@/components/ScheduleSelector/ScheduleSelectComponent'
import ServiceSelectComponent from '@/components/ScheduleSelector/ServiceSelectComponent'
import { SERVICE_TYPES } from '@/config/app'
import { AvailabilityType, computeDayOptions, OptionsType } from '@/components/DaysSelector/DaysSelector'
import dayjs from 'dayjs'
import TitleElement from '@/ui/TitleElement'
import NothingToShow2 from '../NothingToShow2'

export type ScheduleItemType = {
	start_time: string
	final_time: string
}

export type ServiceType = {
	label: string
	options: ScheduleItemType[]
}

export type ScheduleItem = {
	label: string
	value: ScheduleItemType
}

export type ServicesType = {
	[key: string]: ServiceType
}

export type AvailableItem = {
	available_end_time: string
	available_start_time: string
}

export const formatFromDate = (date: string): string => {
	return dayjs(date, 'HH:mm:ss').format('HH:mm')
}

export const computeScheduleOptions = (
	services: ServicesType,
	days: any,
	availability: AvailabilityType[],
	serviceName: string
): ScheduleItem[] => {
	let options: ScheduleItem[] = []

	// GENDER | RANGER
	if (serviceName === SERVICE_TYPES.GENDER) {
		// @ts-ignore
		const selectedItem = serviceName ? services[serviceName] : null

		if (selectedItem) {
			options = selectedItem.options.map((item: ScheduleItemType) => {
				const nValue = {
					start_time: formatFromDate(item.start_time),
					final_time: formatFromDate(item.final_time),
				}
				return {
					label: `${nValue.start_time} - ${nValue.final_time}`,
					value: item,
				}
			})
		}
	} else {
		const dayOptions = computeDayOptions(days, availability)
		const hasOneDay = dayOptions.filter((item: OptionsType) => item.disabled === false) // at least exists one day

		if (availability && availability.length && hasOneDay.length) {
			const availableTimes: AvailableItem[] = availability[0].aviable_times

			options = availableTimes.map((item: AvailableItem) => {
				const nValue = {
					start_time: formatFromDate(item.available_start_time),
					final_time: formatFromDate(item.available_end_time),
				}

				return {
					label: `${nValue.start_time} - ${nValue.final_time}`,
					value: {
						start_time: item.available_start_time,
						final_time: item.available_end_time,
					},
				}
			})
		}
	}

	return options
}

export const computePreferences = (services: any) => {
	const preferences = Object.keys(services).map((key: string) => {
		const item: ServiceType = services[key]
		return {
			value: key,
			label: item.label,
		}
	})

	return preferences
}

export type Props = {
	status: string
	availability: AvailabilityType[]
	onScheduleChange: (schedule: ScheduleItemType) => void
	onServiceChange: (service: string) => void
}

const ScheduleSelector: React.FC<Props> = ({ status, onServiceChange, onScheduleChange, availability }) => {
	// Local state
	const [scheduleOptions, setScheduleOptions] = useState<ScheduleItem[]>([])
	const {
		data: { services, days },
	} = useAppSelector(selectPreferences)

	const {
		control,
		setValue,
		resetField,
		formState: { errors },
	} = useFormContext()


	// Handlers
	const handleServiceChange = useCallback(
		(value: string) => {
			const options: ScheduleItem[] = computeScheduleOptions(services as any, days, availability, value)
			setScheduleOptions(options)

			if (onServiceChange) {
				onServiceChange(value)
			}
		},
		[services, days, availability, onServiceChange]
	)

	const handleSelectorChange = useCallback(
		async (schedule: ScheduleItemType) => {
			if (onScheduleChange) {
				onScheduleChange(schedule)
			}
		},
		[onScheduleChange]
	)
	// Component Mount
	useEffect(() => {
		//- console.log('ScheduleSelector::', availability)
		resetField('schedule')
		setScheduleOptions([])
	}, [availability, resetField])

	useEffect(() => {
		if(availability.length>0){
			handleServiceChange('GENDER')
		}
	}, [availability])
	



	// UI
	return (
		<div className="schedule-selector my-4">
			<div className="container max-w-[980px] mx-auto ">
				<header className="header">
						{/* <div
							className='bg-[url("/assets/imgs/3.svg")] bg-no-repeat p-8 md:m-0 mt-4  '
							style={{ width: 50, height: 50 }}
						></div> */}
						<TitleElement className=" mt-5 md:mt-0  md:mx-0">
						Selecciona un rango de horario para que te visitemos
						</TitleElement>
					{/* <div className="mt-[2rem] md:mt-[1rem] text-center">
						<span className="regular text-[#515151] text-[23px] mb-6 pb-8 ">
							Por favor selecciona el tipo de servicio que más se ajuste a tus necesidades
						</span>
					</div> */}
				</header>
				<section className="main w-full  min-w-[320px] md:max-w-[440px]  pt-4 mb-4">
					{/* <div className="form-item pt-8  ">
						<Controller
							name="service"
							control={control}
							render={({ field: { value, onChange } }) => (
								<ServiceSelectComponent
									value={value}
									options={computePreferences(services)}
									onChange={(value: string) => {
										//- console.log('service::', value)
										setValue('service', value) // working with yup validator
										onChange(value) // HoC Form
										handleServiceChange(value) // Compute schedule values
									}}
									disabled={status === 'loading'}
								/>
							)}
						/>
						<ErrorItem message={errors.service?.message} />
					</div> */}
					<div className=" md:justify-start grid  place-content-center justify-center form-item mb-4">
						<Controller
							name="schedule"
							control={control}
							render={({ field: { value, onChange } }) => (
								<ScheduleSelectComponent
									value={value}
									options={scheduleOptions}
									onChange={(value: ScheduleItemType) => {
										if (value) {
											//  console.log('schedule::', value) // working with yup validator
											setValue('schedule', value) // working with yup validator
											onChange(value) // HoC Form
											handleSelectorChange(value) // Expose the value
										}
									}}
									disabled={status === 'loading'}
								/>
							)}
						/>
						{scheduleOptions.filter((item: ScheduleItem) => item).length === 0 ? (
							<NothingToShow2 label="No hay horarios  para mostrar" />
						) : null}
						<ErrorItem message={errors.schedule?.message} />
					</div>
				</section>
			</div>
		</div>
	)
}

export default ScheduleSelector
