import React, { useCallback, useEffect, useState } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { isMobile } from 'react-device-detect'
import Geocode from 'react-geocode'
import type { AddressType } from '@/types/register'

Geocode.setApiKey(process.env.NEXT_PUBLIC_GOOGLE_API_KEY || '')

export const getComponentItem = (type = 'sublocality', addressArray: any[]): string => {
	for (let i = 0; i < addressArray.length; i++) {
		if (addressArray[i].types[0] && addressArray[i].types.includes(type)) {
			const neighborhood = addressArray[i].long_name
			return neighborhood
		}
	}

	return ''
}

export const getAddressComponents = (addressArray: any[]): AddressType => {
	const colony: string = getComponentItem('sublocality', addressArray)
	const state: string = getComponentItem('administrative_area_level_1', addressArray)
	const country: string = getComponentItem('country', addressArray)
	const street: string = getComponentItem('route', addressArray)
	const street_1: string = getComponentItem('route', addressArray)
	const street_2: string = getComponentItem('route', addressArray)
	const reference: string = getComponentItem('route', addressArray)
	const ext_number: string = getComponentItem('street_number', addressArray)
	const zip_code: string = getComponentItem('postal_code', addressArray)
	const int_number: string = ''

	return {
		street,
		street_1,
		street_2,
		reference,
		colony,
		state,
		country,
		ext_number,
		int_number,
		zip_code,
	}
}

export const getAddressFrom = (newLat: number, newLng: number): Promise<AddressType | string> => {
	return new Promise((resolve, reject) => {
		const lat = String(newLat)
		const lng = String(newLng)

		Geocode.fromLatLng(lat, lng).then(
			(response: any) => {
				if (response?.results) {
					const addressArray = response.results[0].address_components
					const data = getAddressComponents(addressArray)

					resolve(data)
				} else {
					reject('Address can´t be retrieved')
				}
			},
			(error: { message: string }) => {
				reject('Caught Error::fromLatLng -> ' + error.message)
			}
		)
	})
}

export type GeoLocationType = {
	lat: number
	lng: number
}

export type LocationType = {
	location: GeoLocationType
}

export type GoogleMapViewProps = {
	zoom?: number
	location: GeoLocationType
	onGeoCodeDragEnd: (data: AddressType, location: GeoLocationType) => Promise<void>
}

export const GoogleMapView = ({ location, zoom = 16, onGeoCodeDragEnd }: GoogleMapViewProps) => {
	const containerStyle = {
		width: '100%',
		height: isMobile ? '293px' : '430px',
	}

	const [mapInstance, setMapInstance] = useState<any>()

	useEffect(() => {
		if (mapInstance) {
			mapInstance.panTo(location)
		}
	}, [mapInstance, location])

	const onLoad = useCallback((_map) => {
		setMapInstance(_map)
	}, [])

	const handleDragEnd = useCallback(
		async ({ latLng }) => {
			let newLat = latLng.lat(),
				newLng = latLng.lng()

			// Call API
			try {
				const address: AddressType | string = await getAddressFrom(newLat, newLng)

				if (onGeoCodeDragEnd) {
					await onGeoCodeDragEnd(address as AddressType, {
						lat: newLat,
						lng: newLng,
					})
				}
			} catch (error: any) {
				console.log('getAddressFrom::', error.message)
			}
		},
		[onGeoCodeDragEnd]
	)

	const onUnmount = useCallback(() => {
		//setMap(null)
	}, [])

	return (
		<div className="map-view">
			{/*  @ts-ignore */}
			<GoogleMap
				id="map-view"
				options={{
					gestureHandling: 'cooperative', // 'greedy'
				}}
				mapContainerStyle={containerStyle}
				center={location}
				zoom={zoom}
				onLoad={onLoad}
				onUnmount={onUnmount}
			>
				{/*  @ts-ignore */}
				<Marker icon={'/assets/imgs/ping.svg'} position={location} onDragEnd={handleDragEnd} draggable={true} />
			</GoogleMap>
		</div>
	)
}

export default React.memo(GoogleMapView)
