import PropTypes from 'prop-types'
import Head from 'next/head'
import {SEO_DATA} from '@/config/app'

type IconType = {
    src: string
    sizes: string
    type: string
}

type HeadSeoProps = {
    title: string
    description: string
    themeColor: string
    image: string
    canonical: string
    keywords: string
    icons: {
        [name: string]: IconType
    }
    opengraph: {
        site: string
        image: string
        type: string
    }
}

const HeadSeo = (props: HeadSeoProps) => {
    const {title, description, canonical, image, keywords, themeColor, opengraph} = props
    const siteTitle = `Väissen | ${title}`
    return (
        <Head>
            <title>{siteTitle}</title>
            <meta charSet="utf-8"/>
            <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
            />
            <link rel='canonical' href={canonical} />

            <meta name="description" content={description}/>
            <meta name="keywords" content={keywords}/>
            <meta name="theme-color" content={themeColor}/>

            {/* <!-- Schema properties --> */}
            <meta itemProp="name" content={siteTitle}/>
            <meta itemProp="description" content={description}/>
            <meta itemProp="image" content={image}/>

            {/* <!-- Open Graph properties --> */}
            <meta itemProp="og:site_name" content={siteTitle}/>
            <meta itemProp="og:title" content={siteTitle}/>
            <meta itemProp="og:description" content={description}/>
            <meta itemProp="og:url" content={opengraph.site}/>
            <meta itemProp="og:image" content={opengraph.image}/>
            <meta itemProp="og:type" content={opengraph.type}/>

            <link rel="icon" href="/favicon.png" type='image/png' sizes='500x500'/>
            <link rel="apple-touch-icon" href="/apple-touch-icon.png"/>
        </Head>
    )
}

HeadSeo.defaultProps = {
    title: SEO_DATA.meta.title,
    description: SEO_DATA.meta.description,
    image: SEO_DATA.meta.social.graphic,
    themeColor: SEO_DATA.themeColor,
    keywords: SEO_DATA.meta.keywords,
    icons: SEO_DATA.icons,
    opengraph: SEO_DATA.opengraph,
    canonical: SEO_DATA.canonical,
}

HeadSeo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
}

export default HeadSeo
