import { useCallback, useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import ErrorItem from '@/components/AddressView/ErrorItem'
import { useAppSelector } from '@/app/hooks'
import { selectPreferences } from '@/features/preferences/preferencesSlice'
import { AvailableItem } from '@/components/ScheduleSelector/ScheduleSelector'
import TitleElement from '@/ui/TitleElement'
import NothingToShow2 from '@/components/NothingToShow2'
import FrecuencySelectorComponent from './FrecuencySelectorComponent'

export type AvailabilityType = {
	alcaldia: string
	asenta: string
	available: boolean
	available_start_time: string
	available_end_time: string

	available_on_friday: boolean
	available_on_monday: boolean
	available_on_saturday: boolean
	available_on_sunday: boolean
	available_on_thursday: boolean
	available_on_tuesday: boolean
	available_on_wednesday: boolean

	estado: string
	neighborhood: string
	zip_code: string

	// new property
	aviable_times: AvailableItem[]
}

export type DaysType = {
	[name: string]: string
}

export type OptionsType = {
	value: string
	label: string
	disabled: boolean
}

export const computeDayOptions = (days: DaysType, availability: AvailabilityType[]): OptionsType[] => {
	let first_item = {}
	if (availability.length > 0) {
		first_item = availability[0]
		// Elements for UI component: Selecciona tus días de entrega
		const daysOptions = Object.keys(days).map((key: string) => {
			const element = 'available_on_' + key.toLowerCase()

			let isAvailable: boolean = false
			if (first_item.hasOwnProperty(element)) {
				// @ts-ignore
				isAvailable = first_item[element]
			}

			// @ts-ignore
			return { value: key, label: days[key], disabled: !isAvailable }
		})
		return daysOptions
	}
}

export type Props = {
	availability: AvailabilityType[]
	onScheduleChange: (schedule: any) => void
	status: string
}

const FrecuencySelector = ({ availability, onScheduleChange, status }: Props) => {
	// Local state
	const [daysOptions, setDaysOptions] = useState<OptionsType[]>([])

	// Global state
	const {
		data: { VISIT_FREQUENCY },
	} = useAppSelector(selectPreferences)

	const {
		control,
		setValue,
		formState: { errors },
		resetField,
	} = useFormContext()

	// Component Mount
	useEffect(() => {
		if (availability) {
			const options = computeDayOptions(VISIT_FREQUENCY, availability)
			setDaysOptions(options)
			setValue('visit_frecuency', 'WEEKLY') 
			handleSelectorChange('WEEKLY')
		}
		//- console.log('setDaysOptions::', options)
	}, [VISIT_FREQUENCY, availability, setValue])

	const handleSelectorChange = useCallback(
		async (schedule: any) => {
			if (onScheduleChange) {
				onScheduleChange(schedule)
			}
		},
		[onScheduleChange]
	)

	// Handlers

	// UI
	return (
		<div className="day-selector my-4">
			<div className="container max-w-[980px] mx-auto ">
				<header className="header">
					{/* <div
							className='bg-[url("/assets/imgs/2.svg")] bg-no-repeat p-8 m-auto md:mt-2  my-auto'
							style={{ width: 50, height: 50 }}
						></div> */}
					<TitleElement className="md:w-[700px]  mt-5 md:mt-0 ml-2 pt-2">¿Con qué frecuencia te gustaría que te visitemos?</TitleElement>
					{/* <div className='mt-[2rem]  md:mt-[1rem]' >
						<span className="regular text-[#515151] text-[23px] my-6">
							Por favor selecciona el día de tu preferencia
						</span>
					</div> */}
				</header>
				<section className="  main w-full mx-auto min-w-[320px] md:max-w-[769px] mb-4 pt-4">
					<div className="md:justify-start grid  place-content-center justify-center form-item mb-4">
						<Controller
							name="visit_frecuency"
							control={control}
							render={({ field: { value, onChange } }) => (
								<FrecuencySelectorComponent
									value={value}
									options={daysOptions}
									onChange={(value: any) => {
										if (value) {
											 // working with yup validator
											setValue('visit_frecuency', value) // working with yup validator
											onChange([value]) // HoC Form
											handleSelectorChange(value) // Expose the value
										}
									}}
									disabled={status === 'loading'}
								/>
							)}
						/>
						{!daysOptions ? (
							<NothingToShow2 label="No hay frecuencia  disponibles para mostrar" />
						) : null}
						<ErrorItem message={errors.days?.message} />
					</div>
				</section>
			</div>
		</div>
	)
}

export default FrecuencySelector
