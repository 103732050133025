import React, { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { BiCurrentLocation } from 'react-icons/bi'
import { usePosition } from 'use-position'
import { useFormContext } from 'react-hook-form'
import { Button, message, Modal } from 'antd'
import { BsCheck2 } from 'react-icons/bs'
import Image from 'next/image'
import TitleElement from '@/ui/TitleElement'

import { modalProps, paths } from '@/config/app'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import ApartmentSelect from '@/components/AddressView/ApartmentSelect'
import AddressForm from '@/components/AddressView/AddressForm'
import SearchAsync from '@/components/SearchAsync'
import { getAddressComponents, getAddressFrom } from '@/components/AddressView/GoogleMapView'

import type { GeoLocationType } from '@/components/AddressView/GoogleMapView'
import type { AddressType, Apartment } from '@/types/register'
import type { AvailabilityType } from '@/components/DaysSelector/DaysSelector'

import { selectRegister } from '@/features/register/registerSlice'
import { fetchCoverages } from '@/services/app.service'
import {
	resetAllData,
	setAddressData,
	setAvailability,
	setCustomerDataKey,
	setGeoLocation,
} from '@/features/register/registerSlice'
import { isMobile } from 'react-device-detect'

const GoogleMapView = dynamic(() => import('./GoogleMapView'), {
	ssr: false,
})

export const useGetPosition = () => {
	const position = usePosition(true, { enableHighAccuracy: true } as any)

	return {
		getCurrentPosition: () => {
			return {
				position: {
					lat: position.latitude,
					lng: position.longitude,
				},
			}
		},
	}
}

export type Props = {
	onCoverageClicked?: () => void
}

const AddressView: React.FC<Props> = ({ onCoverageClicked }): JSX.Element => {
	const dispatch = useAppDispatch()
	const position = useGetPosition()
	const router = useRouter()

	const [btnLocationTop, setBtnLocationTop] = useState<number>(0)
	const [btnCoverageTop, setBtnCoverageTop] = useState<number>(0)
	const btnCoverageRef = useRef<HTMLDivElement>(null)
	const btnLocationRef = useRef<HTMLDivElement>(null)

	const register = useAppSelector(selectRegister)

	// Global hook form
	const { setValue, formState, resetField, setError } = useFormContext()

	// Local hooks
	useEffect(() => {
		if (btnLocationRef.current) {
			setBtnLocationTop(btnLocationRef.current.offsetTop || 0)
		}

		if (btnCoverageRef.current) {
			setBtnCoverageTop(btnCoverageRef.current.offsetTop || 0)
		}
	}, [])

	// Handlers

	const verifyCoverage = (data: AddressType, coverages: AvailabilityType[]) => {
		const { available } = coverages[0]
		if (available && coverages && coverages.length > 0) {
			const msgText: string = `¡Contamos con cobertura en tu zona!`
			message.success(msgText, 5)

			return true
		}

		return false
	}

	const setHookFormData = (data: {}) => {
		for (let [field, value] of Object.entries(data)) {
			setValue(field, value)
		}
	}

	const resetHookFormData = (data: {}) => {
		for (let [field, value] of Object.entries(data)) {
			resetField(field)
		}
	}

	const getCoverages = async (colonia: string, zip_code: string): Promise<AvailabilityType[]> => {
		const onClose = message.loading(`Buscando cobertura en ${colonia}, ${zip_code}...`, 0)
		try {
			// API Call
			const coverages: AvailabilityType[] = await fetchCoverages(colonia, zip_code)

			return coverages
		} catch (error: any) {
			Sentry.captureException(error)
			throw error
		} finally {
			onClose()
		}

		return []
	}

	const handleSelectPlaceChange = async (data: any) => {
		const { geometry, address_components } = data

		if (address_components) {
			const cdata = getAddressComponents(address_components)
			dispatch(setAddressData(cdata))
		}

		if (geometry?.location) {
			dispatch(
				setGeoLocation({
					lat: geometry.location.lat(),
					lng: geometry.location.lng(),
				})
			)
		} else {
			console.log('No geometry::', geometry)
		}
	}

	const handleGeoCodeDragEnd = async (data: AddressType, location: GeoLocationType) => {
		try {
			const nState = {
				...data,
			}
			// React hook form state
			setHookFormData(nState)

			// Send to Global state
			dispatch(setAddressData(nState))
			dispatch(setGeoLocation(location))
		} catch (error: any) {
			if (error?.message) {
				message.warn(error.message, 5)
			} else {
				message.warn('No se pudo obtener la dirección.')
			}
		}
	}

	const handleGetPositionClick = async () => {
		message.loading('Obteniendo ubicación...', 1)
		const data = position.getCurrentPosition()

		if (data?.position) {
			const lat: number = data.position.lat || 0
			const lng: number = data.position.lng || 0

			try {
				// Service Call
				const address: AddressType | string = await getAddressFrom(lat, lng)

				// Send to global state
				setHookFormData(address)
				dispatch(setAddressData(address))
				dispatch(setGeoLocation(data.position))
			} catch (error: any) {
				message.warn('No se pudo obtener su ubicación, intente nuevamente por favor.', 5)
				Sentry.captureException(error)
			}

			window.scrollTo({
				top: btnLocationTop - 64,
				behavior: 'smooth',
			})
		} else {
			// TODO: Clear here
		}
	}

	const handleCoverageClick = async () => {
		// Expose event to external
		if (onCoverageClicked) {
			onCoverageClicked()
		}

		// Clear form
		resetHookFormData({
			days: null,
			service: null,
			schedule: null,
		})

		// setError('days', { type: 'focus' }, { shouldFocus: true })

		try {
			const { apartment } = register.payload.schedule
			const { colony, zip_code, street, state, ext_number, tower_depot_number } = register.payload.address
			if (state === '' || state === 0) {
				message.error('Por favor selecciona un  estado', 5)
				return
			}

			if (colony === '' || zip_code === '') {
				message.error('Por favor ingresa la colonia y el código postal', 5)
				return
			}
			if (street === '') {
				message.error('Por favor ingresa la calle', 5)
				return
			}

			if (ext_number === '') {
				message.error('Por favor ingresa el numero exterior', 5)
				return
			}

			if (apartment === null || apartment === 0) {
				message.error('Por favor selecciona tu tipo de vivienda', 5)
				return
			} else if (apartment > 7) {
				if (tower_depot_number === '' || tower_depot_number === 0) {
					message.error('Por favor selecciona el nombre del edificio', 5)
					return
				}
			}

			// API Call
			const coverages = await getCoverages(colony, zip_code || '')

			const hasCoverage = verifyCoverage({ colony, zip_code } as AddressType, coverages)

			if (hasCoverage) {
				dispatch(setAvailability(coverages))
				window.scrollTo({
					top: btnCoverageTop +50,
					behavior: 'smooth',
				})
			} else {
				dispatch(setAvailability([]))
				Modal.info({
					...modalProps,
					closable: true,
					content: (
						<div className="flex items-center flex-col">
							<div className="text-lg mb-4 text-center w-full">
								Por el momento no contamos con cobertura en tu zona
							</div>
							<Button
								type="link"
								size="large"
								onClick={() => {
									dispatch(
										setCustomerDataKey({
											key: 'type',
											value: 'PROSPECT',
										})
									)
									message.destroy()
									router.push(paths.register.customer.path)
								}}
							>
								<span className="w-[300px] md:w-full text-sm" style={{ whiteSpace: 'pre-wrap' }}>
									Quiero dejar mis datos para que me contacten más adelante
								</span>
							</Button>
						</div>
					),
				})
			}
		} catch (error: any) {
			if (error?.message) {
				message.error(error.message, 5)
			} else {
				message.warn('No se pudo obtener la cobertura')
			}

			dispatch(setAvailability([]))
		}
	}

	return (
		// className="address-section my-4"
		<section id="registro" className="address-section">
			<div className="container w-full mx-auto px-6">
				<main className="main py-4">
					<div className="sync-google-map">
						<div className="search-async-wrapper flex flex-wrap justify-around md:mt-[1rem] ">
							<div className=" flex flex-col md:w-1/2 max-w-full min-h-full  ">
								<header className="header flex flex-col  ">
									{isMobile ? (
										<TitleElement>
											Ingresa tu dirección para poder ofrecerte nuestro servicio
										</TitleElement>
									) : (
										<TitleElement>
											Ingresa tu dirección <br /> para poder ofrecerte nuestro servicio
										</TitleElement>
									)}

									{/* <div className="text-left mb-4 mt-[1rem]">
										<p className="regular text-[#888888] text-[19px] md:text-[22px]   ">
											Prueba nuestro <span className="font-[400]"> servicio </span>
											<br /> y recibe tu garrafón en el horario que necesites.
											para que podamos agendar tu <span className="font-[400]">visita </span>
										</p>
									</div> */}
									{/* <div className="md:max-w-[650px] mb-8 md:w-5/6">
										<SearchAsync onSelectPlaceChange={handleSelectPlaceChange} />
									</div> */}
									{/* <div className=" flex flex-row  m-auto md:m-0">
								
										<div  ref={btnLocationRef} className="content h-[80px] rounded-[10px] bg-[#081C46] ml-3">
											<Button
											icon={
												<img
													src="/assets/imgs/Ubicación.svg"
													alt="success"
													className="mb-[2rem] md:mb-[1rem] ubication-icon inline-block"
												/>
											}
												className=" w-full"
												type="link"
												size="large"
												onClick={handleGetPositionClick}
											>
												<div className='ml-[4rem] h-[3rem] top-[1rem] bg-[#FFFFFF] w-[1px] absolute'></div>
												<span className="regular text-[#FFFFFF]  text-[18px] px-2 ml-8 ">
													Usar mi  <br />  ubicación actual 
												</span>
											</Button>
										</div>
									</div> */}
								</header>
							</div>
							<div className="map-container   md:w-1/2 max-w-full min-h-full mx-auto w-full "></div>
							{/* <div className="map-container   md:w-1/2 max-w-full min-h-full mx-auto w-full ">
								<GoogleMapView
									location={register.payload.location}
									onGeoCodeDragEnd={handleGeoCodeDragEnd}
								/>
							</div> */}
						</div>

						{/*<span className="text-xs text-gray-label-500 mb-2">
							Ayúdanos a precisar tu domicilio moviendo el pin sobre el mapa
						</span>*/}

						{/*<ApartmentSelect />*/}

						<AddressForm />

						<div
							ref={btnCoverageRef}
							className="coverage-button container min-w-full  md:mx-0 mx-auto flex  md:justify-start md:max-w-[500px]   pb-5 "
						>
							<div className="md:min-w-[300px] min-w-full mx-auto md:mx-0">
								<Button
									onClick={handleCoverageClick}
									className="button-bg-blue min-w-full  "
									size={'large'}
								>
									<span className="text-2xl  ">Verificar tu cobertura</span>
								</Button>
							</div>
						</div>
					</div>
				</main>
			</div>
		</section>
	)
}

export default AddressView
