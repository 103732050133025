import React from 'react'
import { Radio, RadioChangeEvent } from 'antd'
import { ScheduleItemType } from '@/components/ScheduleSelector/ScheduleSelector'
import Item from 'antd/lib/list/Item'

export type Props = {
	disabled_: boolean
	value: any
	options: any[]
	onChange: (value: ScheduleItemType) => void
}

const DaySelectorComponent: React.FC<Props> = ({ value, options, onChange, disabled_ = false }) => {
	const handleChange = (event: RadioChangeEvent) => {
		onChange(event.target.value)
	}


	return (
		<Radio.Group
			disabled={disabled_}
			value={value}
			className="grf-radio-group w-[350px]"
			name="visit_frecuency"
			// buttonStyle="solid"
			onChange={handleChange}
		>
			{options && options
			.filter((item: any) => item.disabled)
				.map((item: any) => {

					const selectedClass = value && item.value === value[0] || item.value === value  ? '  schedule-selected uppercase ' : '  uppercase'
					return (
						<Radio.Button
							className={selectedClass}
							key={item.label}
							value={item.value}
							// disabled={item.disabled}
						>
							{item.label}
						</Radio.Button>
					)
				})}
		</Radio.Group>
	)
}

export default DaySelectorComponent
