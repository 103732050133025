import React from 'react'

export type Props = {
	children: React.ReactElement | string
	className?: string
}

const TitleElement: React.FC<Props> = ({ children, className }) => {
	return (
		<div data-name="title-element" className={className}>
			{/* font-black */}
			<div className="font-title text-2xl  md:text-3xl text-center  md:text-left text-[#888888] !important ">{children}</div>
		</div>
	)
}

export default TitleElement
