import Carousel from 'react-multi-carousel'
import Loader from '@/components/Loader'
import type { Product } from '@/types/products'
import React from 'react'
import TextElement from '@/ui/TextElement'
import NothingToShow from '@/components/NothingToShow'
import TitleElement from '@/ui/TitleElement'

const responsive = {
	tablet: {
		breakpoint: { max: 4024, min: 564 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 564, min: 0 },
		items: 1,
	},
}

const PLAY_SPEED: number = 30

export type ProductItemProps = {
	product: Product
}

const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
	return (
		<div className=" product-item rounded-md bg-white w-[250px] md:min-h-[340px] px-4 py-4 relative">
			<div className="flex flex-col justify-center items-center ">
				<div className="pt-2 border-2  w-[265px] rounded-[1rem] bg-[#D4D4D44D] ">
					<img
						className="m-auto"
						src={product.product_photo}
						width={160}
						height={160}
						alt={product.product_name}
					/>
				</div>
				{/* justify-between */}
				<div className="h-[100px]  flex flex-col items-center  w-full font-black">
					<div className="product-name my-auto text-gray-600 text-sm px-2 w-auto pag-2">
						<span>{product.product_name} </span>
					</div>
					<div className=" h-[50px] w-[90px] m-auto  ">
						<div className="">
							{product?.price ? (
								<span className="p-[2rem]  text-gray-600">{`$${product.price}`}</span>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

type SingleProductListProps = {
	status: string
	products: Product[]
}

const SingleProductList = ({ status, products }: SingleProductListProps): JSX.Element => {
	if (status === 'loading') {
		return (
			<div className="products grid place-content-center min-h-[200px]">
				<Loader />
			</div>
		)
	}

	if (status === 'idle' && products.length === 0) {
		{
			return (
				<div className="products min-h-[50px] min-w-[268px] flex justify-evenly p-5">
					<NothingToShow label="No hay productos que mostrar" />
				</div>
			)
		}
	}

	const productsList = products.map((product: Product) => <ProductItem key={product.product} product={product} />)

	return (
		// drop-shadow-2xl
		<section className="products  py-9 ">
			<TitleElement className="mt-5 md:mt-0 ml-2 md:ml-6 pt-2 flex md:justify-evenly mb-[3rem] text-center">
				Estos son los precios vigentes de nuestros productos al momento
			</TitleElement>
			<div className=" bg-none container  max-w-[342px] md:max-w-[685px]   ">
				<Carousel
					responsive={responsive}
					swipeable={true}
					autoPlay={false}
					infinite={false}
					keyBoardControl={true}
					autoPlaySpeed={PLAY_SPEED * 1000}
				>
					{productsList}
				</Carousel>
			</div>
		</section>
	)
}

export default SingleProductList
