import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import type { NextPage } from 'next'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { Modal } from 'antd'
import { useCookies } from 'react-cookie'
import Layout from '@/layouts/Layout'
import { EXPIRATION_COOKIE_MINUTES, modalProps, paths } from '@/config/app'
import PopupBlock from '@/components/PopupBlock'
import { resetAllData as resetOrderData } from '@/features/orders/ordersSlice'
import { resetAllData as resetRegisterData } from '@/features/register/registerSlice'
import HomeView from '@/features/register/components/HomeView'
import HeadSeo from "@/components/HeadSeo";

const REDIRECT_TO_404 = false

export const getServerSideProps = async (context: any) => {
	if (REDIRECT_TO_404 && context.req.url === '/') {
		return {
			notFound: true,
		}
	}
	return {
		props: {
			access: true,
		},
	}
}

const RegisterPage: React.FC<NextPage> = () => {
	const router = useRouter()
	const dispatch = useDispatch()
	const [cookies, setCookie] = useCookies(['customer-popup-block'])

	useEffect(() => {
		dispatch(resetOrderData())
		dispatch(resetRegisterData())
	}, [dispatch])

	useEffect(() => {
		const createCookie = (saveCookie: boolean = false) => {
			if (saveCookie) {
				setCookie('customer-popup-block', 'displayed', {
					path: '/',
					expires: dayjs().add(EXPIRATION_COOKIE_MINUTES, 'minutes').toDate(),
				})
			}
		}

		const handleRegisterClick = () => {
			createCookie(true)
			router.push(paths.register.basePath)
		}

		const handleOrderClick = () => {
			createCookie(true)
			router.push(paths.orders.path + '/new-order')
		}

		if (cookies['customer-popup-block'] !== 'displayed') {
			Modal.success({
				...modalProps,
				width: 640,
				centered: true,
				content: <PopupBlock onOrderClick={handleOrderClick} onRegisterClick={handleRegisterClick} />,
			})
		}

		return () => {
			Modal.destroyAll()
		}
	}, [cookies, setCookie, router])

	return (
		<Layout title="register-page">
			<HeadSeo title="Registro" />
			<HomeView />
		</Layout>
	)
}

export default RegisterPage
